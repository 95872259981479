import { DatadogLoggingService } from '@edx/frontend-logging';

// List of all regexes for ignoring errors
const ignoredRegexArray = [
  '^Axios Error.*Possible local connectivity issue.',
];

// Join all ignored regexes with a '|'
const IGNORED_ERROR_REGEX = ignoredRegexArray.join('|');

const config = {
  loggingService: DatadogLoggingService,
  IGNORED_ERROR_REGEX,
};

export default config;
